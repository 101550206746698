<template>

  <b-row>

    <!-- Left -->
    <b-col
      cols="12"
      lg="9"
    >

      <b-alert
        :show="userData.role === 'admin' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        Please see the following list of requested changes.
        <ul
          class="d-flex flex-column flex-wrap mt-50"
          style="max-height: 300px;"
        >
          <li
            v-for="(value, index) in formUpdateChanges"
            :key="index"
          >
            {{ value }}
          </li>
        </ul>
        <hr>
        <div class="text-right">
          <b-button
            variant="danger"
            class="mr-1"
            @click="denyFormUpdate"
          >
            Deny
          </b-button>
          <b-button
            variant="secondary"
            @click="approveFormUpdate"
          >
            Approve
          </b-button>
        </div>
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        You still have pending profile update requests. Please wait for the Admin to review them before making any other changes.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 2"
        variant="primary"
        dismissible
        fade
        style="padding: 0.71rem 1rem; cursor: pointer; border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        The HR/Admin has approved the changes you made.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 3"
        variant="danger"
        dismissible
        fade
        style="border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        <div
          style="padding: 0.71rem 1rem; cursor: pointer;"
          @click="dismissFormUpdate"
        >
          The HR/Admin has denied the changes you made.
        </div>
      </b-alert>

      <b-overlay
        :show="userData.role === 'agent' && formUpdateStatus > 0"
        spinner-type="false"
        opacity="0.25"
        blur
        rounded
      >

        <!-- Card -->
        <b-card>

          <b-row class="hr-form">

            <b-col
              cols="12"
            >

              <b-card-title>
                Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders
              </b-card-title>

              <validation-observer ref="simpleRules">
                <b-form>

                  <input
                    v-model="form.id"
                    type="hidden"
                  >

                  <input
                    v-model="form.cid"
                    type="hidden"
                  >

                  <b-row>
                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* Name"
                        label-for="name"
                      >
                        <label
                          class="sr-only"
                          for="name"
                        >
                          * Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Name"
                          rules="required"
                        >
                          <b-form-input
                            id="name"
                            v-model="name"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* Position"
                        label-for="position"
                      >
                        <label
                          class="sr-only"
                          for="position"
                        >
                          * Position
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Position"
                          rules="required"
                        >
                          <b-form-input
                            id="position"
                            v-model="position"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Position"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* Facility"
                        label-for="facility"
                      >
                        <label
                          class="sr-only"
                          for="facility"
                        >
                          * Facility
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Facility"
                          rules="required"
                        >
                          <b-form-input
                            id="facility"
                            ref="facility"
                            v-model="form.facility"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Facility"
                            title="Facility"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  California law REQUIRES certain persons to report known or suspected abuse of dependent adults or elders. As an employee or volunteer at a licensed facility, you are one of those persons - a “mandated reporter.” <br><br>

                  <strong>PERSONS WHO ARE REQUIRED TO REPORT ABUSE</strong><br><br>

                  <strong>Mandated reporters</strong> include care custodians and any person who has assumed full or intermittent responsibility for care or custody of an elder or dependent adult, whether or not paid for that responsibility (Welfare and Institutions Code (WIC) Section 15630(a)). <strong>Care custodian</strong> means an administrator or an employee of most public or private facilities or agencies, or persons providing care or services for elders or dependent adults, including members of the support staff and maintenance staff (WIC Section 15610.17).<br><br>

                  <strong>PERSONS WHO ARE THE SUBJECT OF THE REPORT</strong><br><br>

                  <strong>Elder</strong> means any person residing in this state who is 65 years of age or older (WIC Section 15610.27). <strong>Dependent Adult</strong> means any person residing in this state, between the ages of 18 and 64, who has physical or mental limitations that restrict his or her ability to carry out normal activities or to protect his or her rights including, but not limited to, persons who have physical or developmental disabilities or whose physical or mental abilities have diminished because of age and those admitted as inpatients in 24-hour health facilities (WIC Section 15610.23).<br><br>

                  <strong>REPORTING RESPONSIBILITIES AND TIME FRAMES</strong><br><br>

                  Any mandated reporter, who in his or her professional capacity, or within the scope of his or her employment, has observed or has knowledge of an incident that reasonably appears to be abuse or neglect, or is told by an elder or dependent adult that he or she has experienced behavior constituting abuse or neglect, or reasonably suspects that abuse or neglect occurred, shall complete form SOC 341, “Report of Suspected Dependent Adult/Elder Abuse” for each report of known or suspected instance of abuse (physical abuse, sexual abuse, financial abuse, abduction, neglect (self-neglect), isolation, and abandonment) involving an elder or dependent adult.<br><br>

                  Reporting shall be completed as follows:
                  <ul>
                    <li class="mt-1">
                      If the abuse occurred in a Long-Term Care (LTC) facility (as defined in WIC Section 15610.47) and resulted in serious bodily injury (as defined in WIC Section 15610.67), report by telephone to the local law enforcement agency immediately and no later than two (2) hours after observing, obtaining knowledge of, or suspecting physical abuse. Send the written report to the local law enforcement agency, the local Long-Term Care Ombudsman Program (LTCOP), and the appropriate licensing agency (for long-term health care facilities, the California Department of Public Health; for community care facilities, the California SOC 341A (3/15) PAGE 1 OF 4 Department of Social Services) within two (2) hours of observing, obtaining knowledge of, or suspecting physical abuse.
                    </li>
                    <li class="mt-1">
                      If the abuse occurred in a LTC facility, was physical abuse, but did not result in serious bodily injury, report by telephone to the local law enforcement agency within 24 hours of observing, obtaining knowledge of, or suspecting physical abuse. Send the written report to the local law enforcement agency, the local LTCOP, and the appropriate licensing agency (for long-term health care facilities, the California Department of Public Health; for community care facilities, the California Department of Social Services) within 24 hours of observing, obtaining knowledge of, or suspecting physical abuse.
                    </li>
                    <li class="mt-1">
                      If the abuse occurred in a LTC facility, was physical abuse, did not result in serious bodily injury, and was perpetrated by a resident with a physician's diagnosis of dementia, report by telephone to the local law enforcement agency or the local LTCOP, immediately or as soon as practicably possible. Follow by sending the written report to the LTCOP or the local law enforcement agency within 24 hours of observing, obtaining knowledge of, or suspecting physical abuse.
                    </li>
                    <li class="mt-1">
                      If the abuse occurred in a LTC facility, and was abuse other than physical abuse, report by telephone to the LTCOP or the law enforcement agency immediately or as soon as practicably possible. Follow by sending the written report to the local law enforcement agency or the LTCOP within two working days.
                    </li>
                    <li class="mt-1">
                      If the abuse occurred in a state mental hospital or a state developmental center, mandated reporters shall report by telephone or through a confidential internet reporting tool (established in WIC Section 15658) immediately or as soon as practicably possible and submit the report within two (2) working days of making the telephone report to the responsible agency as identified below:
                      <ul>
                        <li class="mt-1">
                          If the abuse occurred in a State Mental Hospital, report to the local law enforcement agency or the California Department of State Hospitals.
                        </li>
                        <li class="mt-1">
                          If the abuse occurred in a State Developmental Center, report to the local law enforcement agency or to the California Department of Developmental Services.
                        </li>
                      </ul>
                    </li>
                    <li class="mt-1">
                      For all other abuse, mandated reporters shall report by telephone or through a confidential internet reporting tool to the adult protective services agency or the local law enforcement agency immediately or as soon as practicably possible. If reported by telephone, a written or an Internet report shall be sent to adult protective services or law enforcement within two working days.
                    </li>
                  </ul>

                  <strong>PENALTY FOR FAILURE TO REPORT ABUSE</strong><br><br>

                  <span class="span-underline">Failure to report abuse of an elder or dependent adult is a MISDEMEANOR CRIME,</span> punishable by jail time, fine or both (WIC Section 15630(h)). The reporting duties are individual, and no supervisor or administrator shall impede or inhibit the reporting duties, and no person making the report shall be subject to any sanction for making the report (WIC Section 15630(f)).<br><br>

                  <strong>CONFIDENTIALITY OF REPORTER AND OF ABUSE REPORTS</strong><br><br>

                  The identity of all persons who report under WIC Chapter 11 shall be confidential and disclosed only among APS agencies, local law enforcement agencies, LTCOPs, California State Attorney General Bureau of Medi-Cal Fraud and Elder Abuse, licensing agencies or their counsel, Department of Consumer Affairs Investigators (who investigate elder and dependent adult abuse), the county District Attorney, the Probate Court, and the Public Guardian. Confidentiality may be waived by the reporter or by court order. Any violation of confidentiality is a misdemeanor punishable by jail time, fine, or both (WIC Section 15633(a)).<br><br>

                  <strong>DEFINITIONS OF ABUSE</strong><br><br>

                  <strong>Physical abuse</strong> means any of the following: (a) Assault, as defined in Section 240 of the Penal Code; (b) Battery, as defined in Section 242 of the Penal Code; (c) Assault with a deadly weapon or force likely to produce great bodily injury, as defined in Section 245 of the Penal Code; (d) Unreasonable physical constraint, or prolonged or continual deprivation of food or water; (e) Sexual assault, that means any of the following: (1) Sexual battery, as defined in Section 243.4 of the Penal Code; (2) Rape, as defined in Section 261 of the Penal Code; (3) Rape in concert, as described in Section 264.1 of the Penal Code; (4) Spousal rape, as defined in Section 262 of the Penal Code; (5) Incest, as defined in Section 285 of the Penal Code; (6) Sodomy, as defined in Section 286 of the Penal Code; (7) Oral copulation, as defined in Section 288a of the Penal Code; (8) Sexual penetration, as defined in Section 289 of the Penal Code; or (9) Lewd or lascivious acts as defined in paragraph (2) of subdivision (b) of Section 288 of the Penal Code; or (f) Use of a physical or chemical restraint or psychotropic medication under any of the following conditions: (1) For punishment; (2) For a period beyond that for which the medication was ordered pursuant to the instructions of a physician and surgeon licensed in the State of California, who is providing medical care to the elder or dependent adult at the time the instructions are given; or (3) For any purpose not authorized by the physician and surgeon (WIC Section 15610.63).<br><br>

                  <strong>Serious bodily injury</strong> means an injury involving extreme physical pain, substantial risk of death, or protracted loss or impairment of function of a bodily member, organ, or of mental faculty, or requiring medical intervention, including, but not limited to, hospitalization, surgery, or physical rehabilitation (WIC Section 15610.67).<br><br>

                  <strong>Neglect</strong> (a) means either of the following: (1) The negligent failure of any person having the care or custody of an elder or a dependent adult to exercise that degree of care that a reasonable person in a like position would exercise; or (2) The negligent failure of an elder or dependent adult to exercise that degree of self care that a reasonable person in a like position would exercise. (b) Neglect includes, but is not limited to, all of the following: (1) Failure to assist in personal hygiene, or in the provision of food, clothing, or shelter; (2) Failure to provide medical care for physical and mental health needs. No person shall be deemed neglected or abused for the sole reason that he or she voluntarily relies on treatment by spiritual means through prayer alone in lieu of medical treatment; (3) Failure to protect from health and safety hazards; (4) Failure to prevent malnutrition or dehydration; or (5) Failure of an elder or dependent adult to satisfy the needs specified in paragraphs (1) to (4), inclusive, for himself or herself as a result of poor cognitive functioning, mental limitation, substance abuse, or chronic poor health (WIC Section 15610.57).<br><br>

                  <strong>Financial abuse</strong> of an elder or dependent adult occurs when a person or entity does any of the following: (1) Takes, secretes, appropriates, obtains, or retains real or personal property of an elder or dependent adult for a wrongful use or with intent to defraud, or both; (2) Assists in taking, secreting, appropriating, obtaining, or retaining real or personal property of an elder or dependent adult for a wrongful use or with intent to defraud, or both; or (3) Takes, secretes, appropriates, obtains, or retains, or assists in taking, secreting, appropriating, obtaining, or retaining, real or personal property of an elder or dependent adult by undue influence, as defined in Section 15610.70 (WIC Section 15610.30(a)).<br><br>

                  <strong>Abandonment</strong> means the desertion or willful forsaking of an elder or a dependent adult by anyone having care or custody of that person under circumstances in which a reasonable person would continue to provide care and custody (WIC Section 15610.05).<br><br>

                  <strong>Isolation</strong> means any of the following: (1) Acts intentionally committed for the purpose of preventing, and that do serve to prevent, an elder or dependent adult from receiving his or her mail or telephone calls; (2) Telling a caller or prospective visitor that an elder or dependent adult is not present, or does not wish to talk with the caller, or does not wish to meet with the visitor where the statement is false, is contrary to the express wishes of the elder or the dependent adult, whether he or she is competent or not, and is made for the purpose of preventing the elder or dependent adult from having contact with family, friends, or concerned persons; (3) False imprisonment, as defined in Section 236 of the Penal Code; or (4) Physical restraint of an elder or dependent adult, for the purpose of preventing the elder or dependent adult from meeting with visitors (WIC Section 15610.43).<br><br>

                  <strong>Abduction</strong> means the removal from this state and the restraint from returning to this state, or the restraint from returning to this state, of any elder or dependent adult who does not have the capacity to consent to the removal from this state and the restraint from returning to this state, or the restraint from returning to this state, as well as the removal from this state or the restraint from returning to this state, of any conservatee without the consent of the conservator or the court (WIC Section 15610.06).<br><br>

                  AS AN EMPLOYEE OR VOLUNTEER OF THIS FACILITY, YOU MUST COMPLY WITH THE DEPENDENT ADULT AND ELDER ABUSE REQUIREMENTS, AS STATED ABOVE. IF YOU DO NOT COMPLY, YOU MAY BE SUBJECT TO CRIMINAL PENALTY. IF YOU ARE A LONG-TERM CARE OMBUDSMAN, YOU MUST COMPLY WITH FEDERAL AND STATE LAWS, WHICH PROHIBIT YOU FROM DISCLOSING THE IDENTITIES OF LONG-TERM RESIDENTS AND COMPLAINANTS TO ANYONE UNLESS CONSENT TO DISCLOSE IS PROVIDED BY THE RESIDENT OR COMPLAINANT OR DISCLOSURE IS REQUIRED BY COURT ORDER (Title 42 United States Code Section 3058g(d)(2); WIC Section 9725).

                  <b-row
                    align-v="center"
                    class="mt-1"
                  >
                    <b-col
                      cols="12"
                      lg="1"
                    >
                      I,
                    </b-col>

                    <b-col
                      cols="12"
                      lg="5"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Respondent Name"
                        rules="required"
                      >
                        <b-form-input
                          id="respondent-name"
                          v-model="respondentName"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="6"
                    >
                      , have read and understand my responsibility to report known or suspected abuse of dependent adults or elders. I will comply with the reporting requirements.
                    </b-col>
                  </b-row>

                  <b-row
                    class="mt-1"
                  >
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Signature"
                        label-for="signature"
                      >
                        <label
                          class="sr-only"
                          for="signature"
                        >
                          * Signature
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Signature"
                          rules="required"
                        >
                          <vue-signature-pad
                            ref="signature"
                            v-model="form.signature"
                            :state="errors.length > 0 ? false : null"
                            width="100%"
                            height="150px"
                            :options="{ onBegin, onEnd }"
                            class="form-control"
                            :class="{ 'is-invalid' : errors.length > 0 && form.signature === '' }"
                            title="Signature"
                          />

                          <b-row>

                            <b-col>
                              <small
                                v-if="form.signature === ''"
                                class="text-danger"
                              >
                                {{ errors[0] }}
                              </small>
                            </b-col>

                            <b-col
                              class="text-right mt-1"
                            >
                              <!-- Button Undo -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-dark"
                                class="mr-1"
                                @click="undoSignature"
                              >
                                Undo
                              </b-button>

                              <!-- Button Clear -->
                              <b-button
                                variant="outline-danger"
                                @click="clearSignature"
                              >
                                Clear
                              </b-button>
                            </b-col>
                          </b-row>

                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date"
                          label-for="date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date"
                          >
                            * Date
                          </label>
                          <flat-pickr
                            id="date"
                            ref="datesigned"
                            v-model="form.datesigned"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Date Signed"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <!-- Button Submit -->
                  <b-button
                    variant="primary"
                    type="submit"
                    class="mr-1 d-none"
                    @click.prevent="validationForm"
                  >
                    Submit
                  </b-button>

                </b-form>
              </validation-observer>

            </b-col>
          </b-row>

        </b-card>

      </b-overlay>
    </b-col>

    <!-- Right -->
    <b-col
      cols="12"
      lg="3"
    >

      <!-- Action Buttons -->
      <b-card>

        <!-- Button Preview -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="gradient-main"
          class="mb-75 btn-print-preview"
          block
          @click="$store.dispatch('app-hr/preview')"
        >
          Print Preview
        </b-button>

        <!-- Button Save -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          block
          :disabled="(userData.role === 'agent' && formUpdateStatus > 0) ? true : isNotSaved"
          @click.prevent="validationForm"
        >
          Save
        </b-button>

        <!-- Button Cancel -->
        <b-button
          v-if="action !== 'preview'"
          variant="outline-dark"
          class="mb-75"
          :to="{ name: (userData.role === 'agent' ? 'caregiverLanding' : 'hr') }"
          block
        >
          Cancel
        </b-button>

        <div class="d-flex">
          <!-- Button Previous -->
          <b-button
            variant="outline-dark"
            class="flex-fill mr-25"
            :to="{ name: 'apps-hr-form15', params: { action: action }, query: { id: id } }"
          >
            Previous
          </b-button>

          <!-- Button Next -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="flex-fill ml-25"
            :to="{ name: 'apps-hr-form17a', params: { action: action }, query: { id: id } }"
          >
            Next
          </b-button>
        </div>

        <!-- Spacer -->
        <hr class="mb-50">

        <label for="forms">Form Selection</label>
        <v-select
          id="forms"
          v-model="forms"
          :reduce="(option) => option.name"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formsOptions"
          :clearable="false"
          :disabled="!id"
          class="hr-forms-select"
          @input="$router.push({ name: forms, params: { action: action }, query: { id: id } })"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import SockJS from 'sockjs-client'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BOverlay, BAlert, BCard, BCardTitle, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, VBToggle,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import { required } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { VueSignaturePad } from 'vue-signature-pad'
import { mask } from 'vue-the-mask'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

import { getUserData } from '@/auth/utils'

import hrStoreModule from '../hrStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BOverlay,
    BAlert,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,

    vSelect,
    flatPickr,
    VueSignaturePad,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    mask,
  },
  setup() {
    const HR_APP_STORE_MODULE_NAME = 'app-hr'

    // Register module
    if (!store.hasModule(HR_APP_STORE_MODULE_NAME)) store.registerModule(HR_APP_STORE_MODULE_NAME, hrStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HR_APP_STORE_MODULE_NAME)) store.unregisterModule(HR_APP_STORE_MODULE_NAME)
    })

    const form = ref({})

    const signature = ref(null)

    const name = ref('')
    const respondentName = ref('')
    const position = ref('')

    const input = ref(true)

    const formUpdate = ref({})
    const formUpdateStatus = ref(0)

    const formCopy = ref({})

    if (router.currentRoute.query.id) {
      store.dispatch('app-hr/fetchForm', { id: router.currentRoute.query.id })
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          form.value = {}

          form.value.cid = router.currentRoute.query.id

          // eslint-disable-next-line
          name.value = jsonObjectLiterals.data.firstName + ' ' + jsonObjectLiterals.data.lastName
          // eslint-disable-next-line
          respondentName.value = jsonObjectLiterals.data.firstName + ' ' + jsonObjectLiterals.data.lastName
          position.value = jsonObjectLiterals.data.position

          if (router.currentRoute.params.action === 'edit' || (router.currentRoute.params.action === 'add' && router.currentRoute.query.id)) {
            store.dispatch('app-hr/fetchForm16', { cid: router.currentRoute.query.id })
              .then(response1 => {
                // eslint-disable-next-line
                const jsonObjectLiterals = response1.data.response.data

                if (jsonObjectLiterals.data.cid) {
                  form.value = jsonObjectLiterals.data
                }

                if (jsonObjectLiterals.data.signature) {
                  signature.value.fromDataURL(jsonObjectLiterals.data.signature)
                }

                if (form.value.id) {
                  form.value.form = 'folder4_form3'

                  formCopy.value = JSON.parse(JSON.stringify(form.value))

                  store.dispatch('app-hr/fetchFormUpdate', { form: form.value.form, form_id: form.value.id })
                    .then(response2 => {
                      // eslint-disable-next-line
                      const jsonObjectLiterals = response2.data.response.data

                      // console.log(jsonObjectLiterals.data)

                      if (jsonObjectLiterals.data.status) {
                        formUpdate.value = jsonObjectLiterals.data

                        formUpdateStatus.value = formUpdate.value.status
                      }
                    })
                    .catch(error => {
                      console.error(error)
                    })
                }
              })
              .catch(error => {
                console.error(error)
              })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }

    const userData = ref(getUserData())

    const formsOptions = [
      { label: 'Basic Information', name: 'apps-hr-form' },
      { label: 'Employment Checklist', name: 'apps-hr-form1' },
      { label: 'Employment Application', name: 'apps-hr-form2' },
      { label: 'Employment Skills List', name: 'apps-hr-form3' },
      { label: 'Work Experiences Checklist', name: 'apps-hr-form4' },
      { label: 'Employment Eligibility Verification', name: 'apps-hr-form5' },
      { label: 'Form W-4', name: 'apps-hr-form6' },
      { label: 'Non-Compete and Non-Solicitation Agreement', name: 'apps-hr-form7' },
      { label: 'Employment Verification Fax', name: 'apps-hr-form8' },
      { label: 'Ref Inquiry Form', name: 'apps-hr-form9' },
      { label: 'Request for Live Scan Service - Community Care Licensing', name: 'apps-hr-form10' },
      { label: 'New Hire Orientation Acknowledgment Form', name: 'apps-hr-form11' },
      { label: 'Employee Tuberculin Skin Test (TST) and Evaluation', name: 'apps-hr-form12' },
      { label: 'Home Care Organization Inspection Checklist', name: 'apps-hr-form13' },
      { label: 'Personnel Record (Admin)', name: 'apps-hr-form14a' },
      { label: 'Personnel Record', name: 'apps-hr-form14b' },
      { label: 'Applicant for Home Care Aide Registration', name: 'apps-hr-form14c' },
      { label: 'Criminal Record Statement', name: 'apps-hr-form15' },
      { label: 'Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders', name: 'apps-hr-form16' },
      { label: 'Registered Home Care Aide Training Log A', name: 'apps-hr-form17a' },
      { label: 'Registered Home Care Aide Training Log B', name: 'apps-hr-form17b' },
      { label: 'Workplace Safety', name: 'apps-hr-form18' },
      { label: 'Current Knowledge', name: 'apps-hr-form19' },
      { label: 'Fact Sheet (Home Care Services - April 2017)', name: 'apps-hr-form20' },
      { label: 'Acknowledgement Form - Fact Sheet (Home Care Services)', name: 'apps-hr-form21' },
      { label: 'Training Completion Certificate', name: 'apps-hr-form22' },
    ]

    const flatPickrConfig = {
      dateFormat: 'Y-m-d',
    }

    const vueSignaturePadOptions = {
      penColor: 'rgb(0, 0, 0)',
    }

    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-n01'

    return {
      form,

      signature,

      name,
      respondentName,
      position,

      input,

      formUpdate,
      formUpdateStatus,

      formCopy,

      userData,

      formsOptions,

      flatPickrConfig,
      vueSignaturePadOptions,

      sock,
      sockId,
    }
  },
  data() {
    return {
      action: this.$route.params.action,
      id: this.$route.query.id,

      isNotSaved: false,

      forms: this.$route.name,

      formUpdateChanges: [],

      required,
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'formUpdate.data': function () {
      Object.entries(this.formUpdate.data).forEach(([key, val]) => {
        if (JSON.stringify(val) !== JSON.stringify(this.formCopy[key]) && (Number(val) !== Number(this.formCopy[key]) || String(val) !== String(this.formCopy[key]))) {
          this.formUpdateChanges.push(this.$refs[key].$attrs.title)
        }
      })
    },
  },
  created() {
    this.sock.onopen = () => {
      // console.log('Connection established.')
    }

    this.sock.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.id === this.sockId) {
        this.getSockMsg(data.msg)
      }
    }

    this.sock.onclose = () => {
      // console.warn('Connection closed.')
    }
  },
  methods: {
    getSockMsg(sockMsg) {
      if (sockMsg === true) {
        // Do something
      }
    },
    sendSockMsg(sockMsg) {
      this.sock.send(JSON.stringify({ id: this.sockId, msg: sockMsg }))
    },
    clearSignature() {
      this.$refs.signature.clearSignature()
    },
    undoSignature() {
      this.$refs.signature.undoSignature()
    },
    onBegin() {
      // Do something
    },
    onEnd() {
      const { isEmpty, data } = this.$refs.signature.saveSignature()
      this.form.signature = !isEmpty ? data : ''
    },
    requestFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/requestFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 1

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    denyFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/denyFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          if (jsonObjectLiterals.data.id) {
            self.form = jsonObjectLiterals.data
          }

          if (jsonObjectLiterals.data.signature) {
            self.$refs.signature.clearSignature()
            self.$refs.signature.fromDataURL(jsonObjectLiterals.data.signature)
          }

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    approveFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/approveFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    dismissFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/dismissFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    validationForm() {
      const self = this

      /* const { isEmpty, data } = this.$refs.signature.saveSignature()
      this.form.signature = !isEmpty ? data : '' */

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let msg = 'Do you want to save the changes?'
          if (this.action === 'add') msg = 'Do you want to add these data?'
          Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: msg,
            confirmButtonText: 'Proceed',
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.isNotSaved = true

              store
                .dispatch(this.action === 'add' ? 'app-hr/addForm16' : 'app-hr/updateForm16', this.form)
                .then(response => {
                  // eslint-disable-next-line
                  const jsonObjectLiterals = response.data.response.data

                  this.form.id = jsonObjectLiterals.id

                  if (this.userData.role === 'agent' && this.action === 'edit' && jsonObjectLiterals.count > 0) {
                    self.requestFormUpdate()
                  }

                  Swal.fire({
                    icon: 'success',
                    title: 'Saved!',
                    text: 'Saved successfully.',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .catch(error => {
                  console.error(error)

                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .finally(() => {
                  self.isNotSaved = false
                })
            }
          })
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'Are you sure?',
            // eslint-disable-next-line
            text: 'Please fill-up all of the required fields.',
            confirmButtonText: 'OK',
            showCancelButton: false,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
.span-underline {
  text-decoration: underline;
}
</style>

<style lang="scss" scoped>
.hr-forms-select {
  width: 100%;
  min-width: 182px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 135px;
  }
}

@media (max-width: 1199.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 112px;
    }
  }
}

@media (max-width: 991.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 598px;
    }
  }
}

@media (max-width: 767.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 406px;
    }
  }
}

@media (max-width: 575.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 352px;
    }
  }
}
</style>
